import React from "react";
import LeaderCard from "../components/LeaderCard";
// @ts-ignore
import p1Img from "../assets/img/leaders/p1.jpeg";

// @ts-ignore
import p2Img from "../assets/img/leaders/p2.jpeg";

// @ts-ignore
import p3Img from "../assets/img/leaders/p3.jpeg";

// @ts-ignore
import p4Img from "../assets/img/leaders/p4.jpeg";

// @ts-ignore
import p5Img from "../assets/img/leaders/p5.jpeg";

// @ts-ignore
import p6Img from "../assets/img/leaders/p6.jpeg";
import { ArrowRight } from "@icon-park/react";
import useLocalePrefix from "../hooks/useLocalePrefix";
import { Link } from "gatsby";

const Leaders = () => {
  const prefix = useLocalePrefix();
  return (
    <>
      {/*<div className="section lg:px-32 bg-white">*/}
      {/*  <h2 className="text-center mb-8">*/}
      {/*    Leaders at all levels need <br /> personalised support.*/}
      {/*  </h2>*/}
      {/*  <div className="flex flex-col md:flex-row items-center justify-center gap-5 md:px-10">*/}
      {/*    <LeaderCard*/}
      {/*      bgColor="bg-warm"*/}
      {/*      img={p2Img}*/}
      {/*      title="Nail my Public Speaking skills"*/}
      {/*      des="Marie"*/}
      {/*    />*/}
      {/*    <LeaderCard*/}
      {/*      bgColor="bg-warm-dark"*/}
      {/*      img={p1Img}*/}
      {/*      title="Identify my strengths"*/}
      {/*      des="Edouardo"*/}
      {/*    />*/}
      {/*    <LeaderCard*/}
      {/*      bgColor="bg-warm"*/}
      {/*      img={p3Img}*/}
      {/*      title="Provide constructive Feedbacks"*/}
      {/*      des="Stefen"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className="flex flex-col md:flex-row items-center justify-center gap-5 mt-5">*/}
      {/*    <LeaderCard*/}
      {/*      bgColor="bg-warm-dark"*/}
      {/*      img={p5Img}*/}
      {/*      title="Empower myself as a women leader"*/}
      {/*      des="Paula"*/}
      {/*    />*/}
      {/*    <LeaderCard*/}
      {/*      bgColor="bg-warm"*/}
      {/*      img={p6Img}*/}
      {/*      title="Manage my stress to be more performant"*/}
      {/*      des="Tim"*/}
      {/*    />*/}
      {/*    <LeaderCard*/}
      {/*      bgColor="bg-warm-dark"*/}
      {/*      img={p4Img}*/}
      {/*      title="Develop a stronger vision for my company"*/}
      {/*      des="Paul"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="bg-green-light flex flex-col items-center section">
        <h2 className="text-center mb-8">
          Learn how individualized coaching <br />
          can transform your company
        </h2>
        <Link
          className="border border-gray rounded-full px-5 py-3 flex items-center gap-3"
          to={`${prefix}/talk-to-expert`}
        >
          Get a free consulting call{" "}
          <ArrowRight className="flex items-center" />
        </Link>
      </div>
    </>
  );
};

export default Leaders;
